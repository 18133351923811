import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useStudent = () => {
  // eslint-disable-next-line arrow-body-style
  // const checkStudent = studentCode => {
  //   return store.dispatch('manage-student/checkStudent', { studentCode })
  // }
 const findAll = (...args) => store.dispatch('student/findAll', ...args)
 const findOne = (...args) => store.dispatch('student/findOne', ...args)
 const create = (...args) => store.dispatch('student/create', ...args)
 const update = (...args) => store.dispatch('student/update', ...args)
 const deleteOne = (...args) => store.dispatch('student/deleteOne', ...args)
 const deleteAll = (...args) => store.dispatch('student/deleteAll', ...args)
  

  return {
    findAll,
    findOne,
    create,
    update,
    deleteOne,
    deleteAll
  }
}
