import store from '@/store'

export const useOptionStudent = () => {
  // eslint-disable-next-line arrow-body-style
  // const checkStudent = studentCode => {
  //   return store.dispatch('manage-student/checkStudent', { studentCode })
  // }
 const getCourse = () => store.dispatch('manage-student/getCourse')
 const getMajor = () => store.dispatch('manage-student/getMajor')
  const getLevel = () => store.dispatch('manage-student/getLevel')
 const getStatus = () => store.dispatch('manage-student/getStatus')
  
  
 const handleUpdateOption = product => {
    const { addProductInCart } = useEcommerce()
    store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
  }

  return {
    getCourse,
    getMajor,
    getLevel,
    getStatus,
  }
}
