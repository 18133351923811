<template>
  <div>
    <div class="header">
      <b-button @click="addAction()">Add</b-button>
      <b-button class="ml-2" variant="danger" @click="deleteAllData()">Delete All</b-button>
    </div>
    <div class="body">
        <h3>Search</h3>
        <div class="row">
          <div class="col-3">
            <label for="text">Course & Major</label>
            <b-form-select v-model="courseMajorSearch.selected" :options="courseMajorSearch.options"  />
          </div>
          <div class="col-3">
            <label for="text">Level</label>
            <b-form-select v-model="levelSearch.selected" :options="levelSearch.options"  />
          </div>
           <div class="col-3 align-self-end">
              <b-button variant="primary" @click="searchData()">search</b-button>
            <b-button variant="outline-danger" class="ml-1" @click="clearSearch()">clear</b-button>
          </div>
        </div>
    </div>
    <div class="table">
      <b-table
        :items="items"
        :fields="fields"
        responsive
      >
       <template #cell(fullName)="data">
        {{data.item.prefix}} {{data.item.firstName}} {{data.item.lastName}}
      </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <b-button variant="outline-secondary" size="sm" @click="viewAction(data.item.id)">View</b-button>
            <b-button variant="info" size="sm"  class="ml-1" @click="editAction(data.item.id)">Edit</b-button>
            <b-button variant="danger" size="sm"  class="ml-1" @click="deleteRow(data.item.id)">Delete</b-button>
          </div>
        </template>
      </b-table>

         <b-pagination
          v-model="paging.page"
          :total-rows="paging.totalItem"
          :per-page="paging.pageSize"
          @change="changePage"
          aria-controls="my-table"
          v-show="items.length > 0"
        />
    </div>
      <b-modal ref="myModal" hide-backdrop ok-only no-close-on-backdrop no-close-on-esc hide-footer size="lg" @close="onCloseMyModal">
        <template #modal-title>
          <div class="d-block text-center">
            <h3 v-if="typeModal.add == true">Add Student</h3>
            <h3 v-else-if="typeModal.edit == true">Edit Student</h3>
            <h3 v-else-if="typeModal.view == true">View Student</h3>
         </div>
       </template>

       <div class="body">
         <b-form @submit="onSubmit">
          <div class="row">
            <div class="col-5">
              <label for="text">Course & Major</label>
              <b-form-input  v-model="inputVal.majorCourseName"  :disabled="typeModal.view" v-if="typeModal.view"/>
              <b-form-select
                  v-model="courseMajorSearch.selected"
                  :options="courseMajorSearch.options"
                  required
                  v-else
                />
            </div>
              <div class="col-4">
                <label for="text">Level</label>
                <b-form-input  v-model="inputVal.level"  :disabled="typeModal.view" v-if="typeModal.view"/>
                <b-form-select
                    v-model="levelSearch.selected"
                    :options="levelSearch.options"
                    required
                    v-else
                  />
             </div>
            <div class="col-3">
              <label for="text">Prefix</label>
              <b-form-input  v-model="inputVal.prefix"  :disabled="typeModal.view" v-if="typeModal.view"/>
              <b-form-select
               v-model="prefixSearch.selected"
               :options="prefixSearch.options"
               required
               v-else
               />
            </div>
          </div>
          <div class="row row-mt">
            <div class="col-4">
              <label for="text">FirstName</label>
              <b-form-input  placeholder="วสันต์" v-model="inputVal.firstName" :disabled="typeModal.view"></b-form-input>
            </div>
          <div class="col-4">
              <label for="text">LastName</label>
              <b-form-input  placeholder="ก่อนลา" v-model="inputVal.lastName" :disabled="typeModal.view"></b-form-input>
            </div>
            <div class="col-4">
              <label for="text">Student Code</label>
              <b-form-input  placeholder="60578545758" v-model="inputVal.studentCode" :disabled="typeModal.view"></b-form-input>
            </div>
          </div>
             <div class="footer d-flex justify-content-center mt-5">
              <b-button type="submit" variant="primary" v-show="typeModal.view != true">Save</b-button>
            </div>
          </b-form>
        </div>
    </b-modal>

       <b-modal ref="deleteModal" hide-backdrop ok-only no-close-on-backdrop no-close-on-esc hide-footer size="sm"  @close="onCloseDeleteModal">
        <template #modal-title>
          <div class="d-block text-center">
            <h3 v-if="deleteType.one">Delete Student</h3>
            <h3 v-else-if="deleteType.all">Delete All Student</h3>
         </div>
       </template>
       <div class="body">
          <b-form @submit="onSubmitDelete">
              <div class="row">
              <div class="col-12 text-warning font-weight-bold">
                The Student lists should be delete once a year.
              </div>
            </div>
            <div class="row row-mt">
              <div class="col-8">
                <label for="text-password">Please input password for confirm.</label>
                <b-form-input type="password" v-model="inputDeleteVal.password" @keydown="handleKeyDown"  aria-describedby="password-help-block" required></b-form-input>
                <b-form-text id="password-help-block" />
              </div>
              <div class="col-4 d-flex align-items-end">
                <b-button type="submit" variant="primary">Confirm</b-button>
              </div>
            </div>
          </b-form>
       </div>
       </b-modal>
  </div>
</template>

<script>
import { BButton, BTable, BFormInput, BFormGroup, BForm, BFormSelect, BFormText, BPagination } from "bootstrap-vue";
import { ref, reactive, onMounted, watch, computed, toRefs } from "@vue/composition-api";
import { useStudent } from "./useStudent";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useOptionStudent } from "../../manage-student/useOptionStudent";
// const bvModal = useBvModal();


export default {
  components:{
    BButton,
    BTable,
    BFormGroup, 
    BFormInput,
    BForm,
    BFormSelect,
    BFormText,
    BPagination
  },
  setup(){
    let fields = reactive( [
      { key: 'studentCode', label: 'student code', },
      { key: 'fullName', label: 'full name' },
      { key: 'level', label: 'level' },
      { key: 'courseName', label: 'course' },
      { key: 'majorName', label: 'major' },
      { key: 'action', label: 'action' },
     ]);
    let items = ref([]);

    let itemsOne = ref({});
    let typeModal = reactive({
      add: false,
      edit: false,
      view: false
    })
    let deleteType = reactive({
      one: false,
      all: false
    })
    let myModal = ref();
    let deleteModal = ref()
    let inputVal = reactive({
      id: 0,
      majorCourseName: "",
      level:"",
      prefix:"",
      firstName:"",
      lastName:"",
      studentCode:""
    })
   let levelSearch = reactive({
      selected: null,
      options: [ { value: null, text: 'all' }, { value: 1, text: 'ชั้นปีที่ 1' }, { value: 2, text: 'ชั้นปีที่ 2' }, { value: 3, text: 'ชั้นปีที่ 3' } ]
    })
   let prefixSearch = reactive({
      selected: null,
      options: ref([{ value: null, text: 'all' },{ value: "นาย", text: 'นาย' },{ value: "นางสาว", text: 'นางสาว' }]),
    })
    let courseMajorSearch = reactive({
      selected: null,
      options: reactive([{ value: null, text: 'all' }])
    })
    let paging = reactive({
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalItem: 0,
    });
    let inputDeleteVal = reactive({
      password: "",
    })

    const toast = useToast();
    const {  findAll, findOne, create, update, deleteOne, deleteAll } = useStudent();
    const {  getCourse, getMajor } = useOptionStudent();

    onMounted(async () => {
      await fetchAllStudent();
      await fetchOptionStudent();
    });

      function showModal() {
        myModal.value.show()
      }

      
      function addAction(){
        typeModal.add = true;
        typeModal.edit = false;
        typeModal.view = false;
        prefixSearch.options[0] = { value: null, text: 'เลือก' }
        levelSearch.options[0] = { value: null, text: 'เลือก' }
        courseMajorSearch.options[0] = { value: null, text: 'เลือก' }

        showModal()
      }

      async function editAction(id){
        typeModal.add = false;
        typeModal.edit = true;
        typeModal.view = false;
        await fetchOneStudent(id)
        // set value
        // inputVal.prefix = itemsOne.value.prefix
        inputVal.id =  itemsOne.value.id
        inputVal.firstName = itemsOne.value.firstName
        inputVal.lastName = itemsOne.value.lastName
        inputVal.studentCode = itemsOne.value.studentCode
        prefixSearch.selected = itemsOne.value.prefix
        prefixSearch.options[0] = { value: null, text: 'เลือก' }
        levelSearch.selected = itemsOne.value.level
        levelSearch.options[0] = { value: null, text: 'เลือก' }
        courseMajorSearch.selected = { "courseId": itemsOne.value.major.courseId, "majorId":  itemsOne.value.major.id }
        courseMajorSearch.options[0] = { value: null, text: 'เลือก' }

        showModal()
      }

      
      async function viewAction(id){
        typeModal.add = false;
        typeModal.edit = false;
        typeModal.view = true;
        await fetchOneStudent(id)
        // set value
        inputVal.id =  itemsOne.value.id
        inputVal.prefix = itemsOne.value.prefix
        inputVal.firstName = itemsOne.value.firstName
        inputVal.lastName = itemsOne.value.lastName
        inputVal.studentCode = itemsOne.value.studentCode
        inputVal.prefix = itemsOne.value.prefix
        inputVal.level = "ชั้นปีที่ " + itemsOne.value.level
        inputVal.majorCourseName = `${itemsOne.value.major.course.shortName} ${itemsOne.value.major.name}`
        showModal()
      }

      async function onSubmit(event) {
        event.preventDefault()
        if(typeModal.add){
          await createData()
        }else if(typeModal.edit){
          await updateData()
        }
      }

      function deleteRow(id){
        deleteType.one = true
        deleteType.all = false
        inputVal.id = id
        deleteModal.value.show()
      }

      function deleteAllData(){
        deleteType.one = false
        deleteType.all = true
        deleteModal.value.show()
      }

      async function changePage(page) {
        paging.page = page;
        await fetchAllStudent();
      }

    async function fetchAllStudent(){
        await findAll({
        page: paging.page,
        pageSize: paging.pageSize,
        majorId: courseMajorSearch.selected != null ? courseMajorSearch.selected.majorId : null,
        level: levelSearch.selected,
      })
        .then((response) => {
          let { datas, pagination } = response.data;
          paging.page = pagination.currentPage;
          paging.pageSize = pagination.pageSize;
          paging.totalPage = pagination.totalPages;
          paging.totalItem = pagination.totalItems;
          items.value = datas;

        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    }


    async function fetchOneStudent(id){
        await findOne({id})
        .then((response) => {
          itemsOne.value = response.data;
        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    }

    async function fetchOptionStudent(){
      try {
          const responses = await Promise.all([getCourse(), getMajor()]);

          const [courseData, majorData ] = responses;
           courseData.data.forEach(course => {
              let options = []
              majorData.data.forEach(major =>{
                if(course.id == major.courseId){
                  let option = {
                    value: { courseId: course.id, majorId: major.id },
                    text: major.name
                  };
                  options.push(option);
                }
              })
                courseMajorSearch.options.push({
                  label: course.shortName,
                  options: options
                })
          }); 
        } catch (error) {
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger",  },
          });
        }
    }

    async function createData(){
      await create({
        studentCode: inputVal.studentCode,
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        prefix: prefixSearch.selected,
        level: levelSearch.selected,
        majorId: courseMajorSearch.selected.majorId
      }).then(async (response) =>{
        if (response.status == 200) {
           toast({
            component: ToastificationContent,
            props: { title: "Successfully create student.", icon: "CheckCircleIcon", variant: "success",},
          });

          paging.page = 1
          await fetchAllStudent()
          onCloseMyModal()
        }
         
      }).catch((err) => {
      const { config, response } = err;
      toast({ component: ToastificationContent,
        props: { title: response.data.message,  icon: "XCircleIcon", variant: "danger"},
      });
     })
    }


   async function updateData(){
      await update({
        id: inputVal.id,
        studentCode: inputVal.studentCode,
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        prefix: prefixSearch.selected,
        level: levelSearch.selected,
        majorId: courseMajorSearch.selected.majorId
      }).then(async (response) =>{
        if (response.status == 200) {
           toast({
            component: ToastificationContent,
            props: { title: "Successfully create student.", icon: "CheckCircleIcon", variant: "success",},
          });

          paging.page = 1
          await fetchAllStudent()
          onCloseMyModal()
        }
         
      }).catch((err) => {
      const { config, response } = err;
      toast({ component: ToastificationContent,
        props: { title: response.data.message,  icon: "XCircleIcon", variant: "danger"},
      });
     })
    }

   function clearInput(){
    inputVal.id = 0;
    inputVal.prefix = ""
    inputVal.firstName = ""
    inputVal.lastName = ""
    inputVal.studentCode = ""
    prefixSearch.selected = null
    courseMajorSearch.selected = null
    levelSearch.selected = null
   }


   function clearInputDeleteModal(){
    inputDeleteVal.password = ""
   }

   function onCloseMyModal(){
    typeModal.view = false;
    typeModal.add = false;
    typeModal.edit = false
    clearInput()
    myModal.value.hide()
   }

   function onCloseDeleteModal(){
    deleteType.one = false
    deleteType.all = false

    clearInputDeleteModal()
    deleteModal.value.hide()
   }

   async function onSubmitDelete(event){
    event.preventDefault()
    if(deleteType.one){
      await deleteOne({
        id: inputVal.id,
        password: inputDeleteVal.password,
      }).then(async (response) => {
          if (response.status == 200) {
            toast({
              component: ToastificationContent,
              props: { title: "Successfully delete student.", icon: "CheckCircleIcon", variant: "success",},
            });

            paging.page = 1
            await fetchAllStudent()
            onCloseDeleteModal()
          }
        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    } else if(deleteType.all){
      await deleteAll({
        password: inputDeleteVal.password,
      }).then(async (response) => {
          if (response.status == 200) {
            toast({
              component: ToastificationContent,
              props: { title: "Successfully delete all student.", icon: "CheckCircleIcon", variant: "success",},
            });

            paging.page = 1
            await fetchAllStudent()
            onCloseDeleteModal()
          }
        })
        .catch((err) => {
          const { config, response } = err;
          toast({ component: ToastificationContent,
            props: { title: response.data.message, icon: "XCircleIcon", variant: "danger", },
          });
        });
    }
   }

  async function clearSearch(){
    levelSearch.selected = null
    courseMajorSearch.selected = null
    paging.page = 1;

    await fetchAllStudent();
   }

    async function searchData(){
      paging.page = 1;
      await fetchAllStudent();
    }

   function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }

    return{
      //value
      items, fields, typeModal, myModal, inputVal, levelSearch, prefixSearch, 
      deleteModal, courseMajorSearch, paging, inputDeleteVal, deleteType

      //method
      ,showModal,  addAction, editAction, viewAction, onSubmit, deleteRow, deleteAllData,
      changePage, onCloseMyModal, onCloseDeleteModal, onSubmitDelete, fetchAllStudent,
      clearSearch, searchData, handleKeyDown
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: flex-end;
}

.table{
  margin: 15px 0 0 0;
}

.row-mt{
  margin-top: 15px;
}

.body-confirmQuestionModal{
  background: black;
  color: white;
}
</style>